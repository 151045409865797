@import 'mixins.scss';

.app {
  overflow: hidden;

  a {
    color: #0849ff;
    text-decoration: none;
  }

  .header {
    padding-top: 4.2rem;
    background: url('/assets/img/header-BG.png') 0 0 no-repeat;
    padding-bottom: 5.7rem;

    @media (max-width: 500px) {
      height: 30vh;
      padding-top: 3.4rem;
      background: url('/assets/img/header-BG-mobile.png') 0 0 round;
      padding-bottom: 2.7rem;
    }

    &-box {
      @include box-max-width();
    }

    &__headline {
      color: #ffffff;
      font-size: 5.6rem;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 5.5rem;
      margin: 7rem 0 5.7rem;

      @media (max-width: 500px) {
        font-size: 3.4rem;
        line-height: 3.6rem;
        margin: 3.2rem 0 0;
      }
    }

    &__logo {
      display: inline-block;
      background-size: cover;
      background-position: 0 0;
      background-repeat: no-repeat;

      &_bot {
        background-image: url('/assets/img/culturebot-logo.svg');
        width: 384px;
        height: 66px;
        margin-right: 5.1rem;

        @media (max-width: 500px) {
          background-image: url('/assets/img/culturebot-logo-mobile.svg');
          width: 161px;
          height: 49px;
          margin-right: 1.9rem;
        }
      }

      &_europeana {
        background-image: url('/assets/img/europeana-logo.svg');
        width: 208px;
        height: 72px;

        @media (max-width: 500px) {
          width: 120px;
          height: 42px;
        }
      }
    }
  }

  .mockup {
    overflow: hidden;
    width: 65vw;
    position: absolute;
    top: 160px;
    left: 35vw;

    @media (max-width: 500px) {
      margin-top: -2px;
      height: 80vh;
      position: relative;
      width: 100vw;
      top: 0;
      left: 0;
    }

    > img {
      position: relative;
      left: 65px;

      @media (max-width: 500px) {
        visibility: hidden;
      }
    }

    .widget {
      position: absolute;
      width: 473px;
      height: 995px;
      left: 222px;
      top: 32px;

      @media (max-width: 500px) {
        width: 100%;
        height: 88%;
        top: 0;
        left: 0;
      }

      .rw-conversation-container {
        @media (min-width: 501px) {
          border-radius: 55px;
        }

        .rw-header-buttons {
          display: none;
        }
      }

      .rw-header {
        display: flex;
      }
    }
  }

  .chatbot {
    padding-top: 80.5rem;
    @media (max-width: 500px) {
      padding-top: 0;
    }

    &-box {
      @include box-max-width();
    }

    &__description {
      color: #545454;
      font-size: 2.6rem;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 3.3rem;
      padding: 2rem 0 3.8rem;

      @media (min-width: 501px) {
        width: 45%;
      }

      @media (max-width: 500px) {
        padding: 4.5rem 0 2rem;
        font-size: 2.4rem;
        line-height: 2.6rem;
      }
    }

    &-info {
      display: flex;
      padding-bottom: 8rem;

      @media (max-width: 500px) {
        flex-direction: column;
        padding-bottom: 3.5rem;
      }

      &-card {
        display: inline-block;
        @media (min-width: 501px) {
          width: 32.5%;
          margin-right: 9.2%;
        }

        &__title {
          color: #4f40ff;
          font-size: 2rem;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 2.8rem;

          padding-bottom: 1.3rem;
        }

        @media (max-width: 500px) {
          &:not(&:first-child) {
            &__title {
              margin-top: 3.5rem;
            }
          }
        }

        &__details {
          color: #545454;
          font-size: 2rem;
          letter-spacing: 0;
          line-height: 2.6rem;
        }

        img {
          padding-top: 4.6rem;
          @media (max-width: 500px) {
            width: 100%;
            padding-top: 3.5rem;
          }
        }
      }
    }
  }

  .footer {
    @media (max-width: 500px) {
      padding-bottom: 12px;
    }

    .partners {
      background: url('/assets/img/stripes_BG.png');

      &-box {
        @include box-max-width();

        display: flex;

        @media (min-width: 501px) {
          height: 140px;
          align-items: center;
        }

        @media (max-width: 500px) {
          padding: 23px 0 44px;
          flex-wrap: wrap;
          flex: 0 0 35%;

          &__title {
            width: 100%;
          }
        }
      }

      &__item {
        color: #545454;
        font-size: 2.2rem;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 4.8rem;

        @media (min-width: 501px) {
          margin-right: 7.6rem;
        }

        @media (max-width: 500px) {
          font-size: 2rem;

          display: flex;
          align-items: center;

          &:first-child {
            width: 100%;
          }

          &:not(:first-child) {
            width: 50%;
            padding: 20px 0;

            &:nth-child(odd) {
              justify-content: flex-end;
            }
          }
        }
      }
    }

    .copy-box {
      @include box-max-width();

      color: #545454;
      font-size: 1.6rem;
      letter-spacing: 0.044rem;
      line-height: 4.8rem;

      padding: 1.2rem 0;

      a {
        font-weight: bold;
      }

      @media (max-width: 500px) {
        font-size: 1.6rem;
        line-height: 2.1rem;

        a {
          display: block;
          padding-top: 10px;
        }
      }
    }
  }
}
