.lang-switcher {
  position: absolute;
  top: 555px;
  left: 0;

  @media (max-width: 500px) {
    flex-direction: row;
    justify-content: center;
    position: relative;
    top: 91.5%;
    left: 0;
    z-index: 10;
  }

  display: flex;
  flex-direction: column;

  &__btn {
    width: 41px;
    height: 41px;

    margin: 0.6rem;
    padding: 0;

    outline: none;
    border-radius: 22px;
    border: 1px solid #ffffff;
    background-color: #0849ff;
    box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.25);
    color: #ffffff;
    font-size: 1.8rem;
    letter-spacing: 0;
    line-height: 2.8rem;
    text-align: center;

    cursor: pointer;

    @media (max-width: 500px) {
      width: 39px;
      height: 39px;
      font-size: 1.728rem;
      line-height: 2.688rem;
    }

    @media (min-width: 501px) {
      &:hover {
        background-color: #fac807;
        color: #0849ff;
      }
    }

    &_active {
      background-color: #fac807;
      color: #0849ff;
      font-weight: bold;
    }

    &_warn {
      margin: 2.5rem 0.6rem 0.6rem 0.6rem;
      @media (max-width: 500px) {
        margin: 0.6rem 0.6rem 0.6rem 2.5rem;
      }

      @media (min-width: 501px) {
        &:hover {
          background-color: #f00;
          color: #ffffff;
        }
      }
    }
  }
}
